.ocean {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wave {
  background: url(../../assets/icons/wave.webp) repeat-x;
  position: absolute;
  /* bottom: 0; */
  /* width: 6400px; */
  height: 63px;
  animation: wave 2s ease-out;
  transform: translate3d(0, 0, 0);
  @apply w-[6400px];
}

@keyframes waveAnim {
  0% {
    height: 0;
  }
  100% {
    height: 63px;
  }
}

@keyframes wave {
  0% {
    margin-left: -700px;
    height: 0;
  }
  100% {
    margin-left: 0;
    height: 63px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
